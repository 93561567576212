.all-testimonials__box {
    opacity: 0;
    transform: translateY(20px); /* Optional: adds a slight slide-in effect */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  
  .all-testimonials__box.fade {
    opacity: 1;
    transform: translateY(0);
  }
  