/* Contact.css */

.modal-overlayed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
    opacity: 1;
}

.modal {
    background: #ffffff; /* White background for the modal */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 400px; /* Set max width for the modal */
    width: 90%; /* Allow responsiveness */
    opacity: 1; /* Ensure full opacity */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff4d30;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal p {
    margin: 20px 0; /* Spacing for message */
    color: #333; /* Dark text for better visibility */
    font-size: 1.2rem; /* Slightly larger text */
}
